import Container from 'react-bootstrap/Container';
import { Alert, Button, Card, Table, Modal, Accordion, Form} from 'react-bootstrap';
import {Sida,Rubrik,ContentWrapper,Stycke,StyckeCompact} from "../ContentStyles";
import useBackend, {Loading, CallAPI, CallAPIGet} from '../../services/useBackend';
import {MaterialReactTable} from 'material-react-table';
import React, { useState ,useEffect, useReducer, fetchData,useMemo} from 'react';
import {DateTimeToLocal, MultiselectGetOptions, MultiselectGetDefaultValues, SplitoutLocalDateFromISO, getOptions, metaLookUpTitle, getPeriodOptions} from '../../services/misc';
import EditVerification from './editVerificationComponent';
import { FaFilePdf, FaReceipt, FaEnvelope,FaBars, FaUser, FaFileDownload, FaDownload } from 'react-icons/fa';

function Verifications() {  
  const [editVerificationModalshow, setEditVerificationModalshow] = useState(false);
  const [selectedVerificationData,  setSelectedVerificationData]  = useState({ Verification: {Identity:"", Description:"",  VerificationFiles: [],  BookKeepingTransactions: [] }});  

  const {loading:loadingmeta, data:metadata} = useBackend(process.env.PUBLIC_URL+'/api/meta');    
  const {loading, data, Refresh, Seek} =  useBackend('/api/v1/bookkeeping/verifications');

  

  const editVerificationHandler = async (event, rowData)  =>  {
    let {resultVariant, resultVisible, resultText, errorsException, jsondata} = await CallAPIGet('/api/v1/bookkeeping/verifications/' + rowData.original.Guid);
	  setSelectedVerificationData(jsondata)
	  setEditVerificationModalshow(!editVerificationModalshow);
  }

  const setActivePeriod = async (SelectedBookKeepingPeriodGuid)  =>  {
    console.log(SelectedBookKeepingPeriodGuid);
		const formDataObj = {};
    formDataObj.SelectedBookKeepingPeriodGuid = SelectedBookKeepingPeriodGuid;
    let {resultVariant, resultVisible, resultText, errorsException, jsondata} = await CallAPI('/api/v1/bookkeeping/setactiveperiod/',"PATCH",JSON.stringify(formDataObj));
    setPagination({ pageIndex: 0, pageSize: 25 });
    Refresh();
  }

  const columns = useMemo(() => [    
      { size: 40, accessorKey: 'Identity', header: 'Identity', enableColumnActions: false },
      { size: 40, accessorKey: 'Description', header: 'Description', enableColumnActions: false },

      { size: 20, accessorKey:'Date', header: 'Datum' , enableColumnActions: false,
        Cell: ({ cell }) => SplitoutLocalDateFromISO(cell.getValue()),        
      }
    ]
  );

  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 25 });

  const initialState = { density: 'compact', columnVisibility: { } };      

  const state = { pagination };     

  if (!loading&&!loadingmeta) {
    return (
      <ContentWrapper>
        <EditVerification VerificationData={selectedVerificationData} MetaData={metadata} show={editVerificationModalshow} fn={()=> {setEditVerificationModalshow(!editVerificationModalshow); Refresh();} } setSelectedVerificationData={setSelectedVerificationData} />  
        <Sida>
          <MaterialReactTable columns={columns} data={data.Verifications} state={state} initialState={initialState}  onPaginationChange={setPagination} manualPagination= {false}  autoResetPageIndex= {false}
            muiTablePaginationProps={{ showFirstButton: false, showLastButton: false, rowsPerPageOptions: [25, 50, 75, 100], labelRowsPerPage:"Rader" }}            
            
            renderTopToolbarCustomActions= {() => (
              <div style={{width:"100%"}}>
                <div style={{float:"left"}}>
                  <span>Verifikationer</span>
                </div>

                <div style={{float:"right"}}>                                
                  <Form.Select onChange={ async (e) => {setActivePeriod(e.target.value); }} defaultValue={data.Period.Guid}>
                    {!loading && getPeriodOptions(metadata.BookKeepingPeriods)}
                  </Form.Select>   
                </div>                             

              </div>
            )}
            
 

            muiTablePaperProps={{elevation: 0, sx: {borderRadius: '0',border: '0px dashed #e0e0e0'} }} 
            muiTableBodyRowProps={({ row }) => ({
            onClick: (event) => {editVerificationHandler(event, row);},sx: {cursor: 'pointer'},})}
          />                                             
        </Sida>
        {selectedVerificationData.Verification.Description}<br></br>

      </ContentWrapper>        
    );
  } else {
    return (<Loading />);        
  }    
}

export default Verifications;