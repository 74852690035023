import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ContentDiv } from "./pages/ContentStyles";

import Menu     from "./pages/Menu";
import Footer   from './pages/Footer';

import Home     from "./pages/Home";

import CookieConsent   from "./pages/CookieConcent"

import Login                  from "./pages/common/Login";
import MyPage                 from "./pages/common/MyPage";
import NoPage                 from "./pages/common/NoPage";
import Contact                from "./pages/common/Contact";
import ChangePassword         from "./pages/common/ChangePassword";
import RequestNewPassword     from "./pages/common/RequestNewPassword";
import RequestNewPasswordSet  from "./pages/common/RequestNewPasswordSet";

import BookkeepingVerifications  from "./pages/app/Verifications";
import BookkeepingAccounts       from "./pages/app/BookkeepingAccounts";
import BookkeepingSaldo          from "./pages/app/BookkeepingSaldo";
import BookkeepingTools          from "./pages/app/BookkeepingTools";

import Example                from "./pages/Example";

import Upload from './pages/upload';


export default function App() {
  return (
    <>      
        <BrowserRouter>
        <ContentDiv>
          <Routes>
            <Route index                                  element={<Home />} />

            <Route path="Password/Request/new"            element={<RequestNewPassword />} />
            <Route path="Password/Request/set/:GUID"      element={<RequestNewPasswordSet />} />
            <Route path="Password/change"                 element={<ChangePassword />} />
            
            <Route path="contact"                         element={<Contact />} />           
            <Route path="login"                           element={<Login />} />              
            <Route path="MyPage"                          element={<MyPage />} />   


            <Route path="Example"                         element={<Example />} />       
            <Route path="Uploadx"                         element={<Upload />} />  

            <Route path="Verifications"                   element={<BookkeepingVerifications />} />
            <Route path="Accounts"                        element={<BookkeepingAccounts />} />
            <Route path="Saldo"                        element={<BookkeepingSaldo />} />
            <Route path="Tools"                        element={<BookkeepingTools />} />

            
            <Route path="*"                               element={<NoPage />} />
          </Routes>
          </ContentDiv>
        </BrowserRouter>

      <Footer />
      <CookieConsent />
    </>
  );
}

//ReactDOM.render(<App />, document.getElementById("root"));
const root = ReactDOM.createRoot(document.getElementById("main") );
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

const top = ReactDOM.createRoot(document.getElementById("top") );
top.render(
  <React.StrictMode>
    <Menu />
  </React.StrictMode>
);