import { Container, Button, Navbar, Nav, NavItem, NavDropdown, MenuItem, NavLink,FormControl ,Form, Alert} from 'react-bootstrap';

import Faq from "./Faq";
import React, { useState, Component } from 'react';
import {Sida,Rubrik,Stycke,ContentWrapper} from "./ContentStyles";


const shoot = () => {
  alert();
}

const Home = () => {

   return(
        <ContentWrapper>
        <Container>
        <Sida>

          <Rubrik>Vad är Redovisa.nu?</Rubrik>
          <Stycke>
            Det är lite tidigt att berätta vad detta handlar om men den som väntar på något gott väntar sällan för länge :)
          </Stycke> 
    
          <Faq />
          
        </Sida>    
        </Container>

        </ContentWrapper>
      );
   }

export default Home;