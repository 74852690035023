import styled from 'styled-components';


export const ContentWrapper = styled.div`
	width: 100%; /* Full width */
	padding-bottom:20px;
	z-index: 90;
	background-color:#fff;	
    flex: 1;
`;

export const Sida = styled.div`
	display_: flex;

	flex-grow-1;
	flex-direction: column;
	justify-content: center;
	font-size: 22px;
	margin: auto ;
	padding-top:20px;
	width: 100%; /* Full width */
	max-width: 1200px;

	@media (max-width: 991px) {
		padding-top:20px;
	} 

	@media (min-height: 500px) {
		min-height: ${window.innerHeight-340}px;
	} 	
`



export const ContentDiv = styled.div`
	z-index: 97;
`;


export const Rubrik = styled.p`
	@media (max-width: 991px) {
	 	font-size: 120%;
	 	font-weight: bold;		
	} 
	background-color_: #00c;
	color: #444;
	margin-bottom: 5px;
	// font-family: Arial, Helvetica, sans-serif;
	font-size: 40px;
`;

export const Stycke = styled.p`
	font-size: 100%;
	color: #444;
	margin-bottom: 40px;
	// font-family: Arial, Helvetica, sans-serif;
	z-index: 90;
`;

export const StyckeCompact = styled.p`
	font-size: 70%;
	color: #444;
	margin-bottom: 40px;
	// font-family: Arial, Helvetica, sans-serif;
	z-index: 90;
`;

export const Button_ = styled.button`
	color: #fff !important;
	border: 2px solid #ccc;
	background-color: #FFC90E !important;
	border-color: #fff !important;
	@media (max-width: 991px) {
	  font-size: 120%;
	  font-weight: bold;
	}   
`;

export const Button = styled.button`
  background: #fff;
  color: #000 !important;

  font-size: 1em;
  margin: 0em;
  padding: 0.25em 1em;
  border: 3px solid #FFC90E;
  border-radius: 3px;
`;