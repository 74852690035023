import { Container, Form, Alert} from 'react-bootstrap';
import {Sida,Rubrik,ContentWrapper,Button} from "./ContentStyles";
import React, { useState, useRef } from 'react';
import axios from 'axios';
import useBackend, {CallAPI, Loading, SpinnerX} from '../services/useBackend';
import { FileUploader } from "react-drag-drop-files";

export default function Upload() {

    const [loading,       setLoadning]      = useState(false);
    const [name, setName] = useState("");
    const [selectedFile, setSelectedFile] = useState(null);
    const [resultVariant, setResultVariant] = useState('warning');
    const [resultText,    setResultText]    = useState('N/A');
    const [resultVisible, setResultVisible] = useState(false);    

    const fileTypes = ["SI", "SE", "txt", "dat"];

    const submitForm = () => {
        const formData = new FormData();
        formData.append("name", "file");
        formData.append("file", selectedFile);
        setLoadning(true);

        axios
        .post("/backend/upload", formData, {headers: {'X-API-KEY': "8VPDG6JWV8TT7N5iUQ38rYig6efkqecy"}} )
        .then((res) => {
            console.log(res.data);
            

            setResultText("Filen laddades upp");
            setResultVariant("success");
            setResultVisible(true);
        })
        .catch((err) => {setResultText("Något gick fel");setResultVisible(true);});

        setSelectedFile(null);
        setLoadning(false);
    };

    const handleChange = (file) => {
        setSelectedFile(file);
        setResultVisible(false);
        setResultText("");
      };

    

    return (
        <ContentWrapper>
            <Container>
                <Sida>
                <Rubrik>Ladda upp fil</Rubrik>

                    <Form>
                        <Form.Group className="mb-3">
                            <FileUploader submitForm="" handleChange={handleChange} name="file"  label="Välj eller släpp en fil här" uploadedLabel="Fil vald, tryck på ladda upp!" multiple={false} maxSize={5} style={{height:"60px", width:"100%"}} height="60px"/>
                            <Form.Label>Tillåtna filtyper: {fileTypes.map((ft) => {return <>{ft} </>} )}</Form.Label>
                        </Form.Group>  

                        <Form.Group className="mb-3">                            
                            <Button disabled={false} className="QmeButton" variant="primary" type="button" onClick={submitForm}>Ladda upp</Button>
                        </Form.Group>  

                        <Form.Group className="mb-3"  hidden={!loading}>
                            <SpinnerX  />
                        </Form.Group>                                      
                            
                        <Form.Group className="mb-3"  hidden={!resultVisible}>
                            <Alert show={resultVisible} variant={resultVariant}  className="FormSpace">{resultText}</Alert> 
                        </Form.Group>                                
                    </Form>
                </Sida>

            </Container>
        </ContentWrapper>
    );
}