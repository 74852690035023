import { Container, Button,Form, Alert} from 'react-bootstrap';
import {Sida,Rubrik,ContentWrapper} from "../ContentStyles";
import useBackend, {CallAPI, Loading, SpinnerX} from '../../services/useBackend';
import React, { useState ,useEffect, useReducer, fetchData,useMemo} from 'react';
import {DateTimeToLocal, MultiselectGetOptions, MultiselectGetDefaultValues, escapedNewLineToLineBreakTag, ConcatLocalDateAndTime2ISO, SplitoutLocalDateFromISO, SplitoutLocalTimeFromISO, getOptions, metaLookUpTitle} from '../../services/misc';

function MyData() {
      const [resultText, setResultText] = useState("N/A");
      const [resultVariant, setResultVariant] = useState("success");
      const [resultVisible, setResultVisible] = useState(false);
      const [spinnerHidden, setSpinnerHidden] = useState(true);    

	const {loading, data, Refresh, Seek} =  useBackend(process.env.PUBLIC_URL+'/api/auth');
    const {loading:loadingmeta, data:metadata} =  useBackend(process.env.PUBLIC_URL+'/api/meta');

    function handleSubmit(e) {
        e.preventDefault();

    
        const formData = new FormData(e.target),
        formDataObj = Object.fromEntries(formData.entries())
        
        fetch('/api/auth/mydata', {
            method: 'PUT',
            headers: {
               'Content-Type': 'application/json',
               'accessToken':localStorage.getItem('access_token'),
            },
    
            body: JSON.stringify(formDataObj),
         }).then(response => response.json())
         .then(data => {
            if (data.errorsException===false) {
                setResultVariant("success");      
                setResultText(data.resultText);        
                setSpinnerHidden(true);
                setResultVisible(true);
            } else {
                setResultVariant("warning");
                setResultText(data.resultText);
                setSpinnerHidden(true);
                setResultVisible(true);
            }
         })
         .catch(error => {
            setResultVariant("warning");
            setResultText("Ett fel uppstod!");
            setSpinnerHidden(true);
            setResultVisible(true);
          });
        }   

    if (!loading&&!loadingmeta) {
    return (
        <ContentWrapper>
                <Sida>
                <Rubrik>Mina Sidor</Rubrik>

                <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3">
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="email" name="Email" placeholder="Email" id="name" defaultValue={data.UserData.email}/>
                    </Form.Group>    

                    <Form.Group className="mb-3" >
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="text" name="name" placeholder="Name" id="name" defaultValue={data.UserData.name} />
                    </Form.Group>

                    <Form.Group className="mb-3" >
                        <Form.Label>Phone</Form.Label>
                        <Form.Control type="text" name="phone" placeholder="Phone" id="phone"  defaultValue={data.UserData.phone}/>
                    </Form.Group>                                                  

                    <Form.Group className="mb-3"  hidden={spinnerHidden}>
                        <SpinnerX  />
                    </Form.Group>                     

                    <Form.Group className="mb-3"  hidden={!resultVisible}>
                        <Alert show={resultVisible} variant={resultVariant}  className="FormSpace">{resultText}</Alert> 
                    </Form.Group>        

                    <Form.Group className="mb-3">
                        <Button className="QmeButton" variant="primary" type="submit">Spara</Button>
                    </Form.Group>    
                </Form>      
            </Sida>
        </ContentWrapper>
    );
  
} else {
    return (<Loading />);        
}    
}


export default MyData;