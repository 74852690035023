import Container from 'react-bootstrap/Container';
import { Alert, Button, Card, Table, Modal, Accordion } from 'react-bootstrap';
import {Sida,Rubrik,ContentWrapper,Stycke,StyckeCompact} from "../ContentStyles";
import useBackend, {XLogOff, IsLoggedIn,Loading, Check, CallAPI, CallAPIGet} from '../../services/useBackend';
import {MaterialReactTable} from 'material-react-table';
import React, { useState ,useEffect, useReducer, fetchData,useMemo} from 'react';
import {DateTimeToLocal, MultiselectGetOptions, MultiselectGetDefaultValues, escapedNewLineToLineBreakTag, ConcatLocalDateAndTime2ISO, SplitoutLocalDateFromISO, SplitoutLocalTimeFromISO, getOptions, metaLookUpTitle, metaLookUpAccountTitle} from '../../services/misc';

function BookkeepingSaldo() {  
  const {loading, data} =  useBackend(process.env.PUBLIC_URL+'/api/reports/saldo');    
  const {loading:loadingmeta, data:metadata} =  useBackend(process.env.PUBLIC_URL+'/api/meta');    

  const columns = useMemo(() => [    
    { size: 40, accessorKey: 'AccountIdentifier',   header: 'Konto', enableColumnActions: false },

    { size: 40, accessorKey:'AccountIdentifier', header: 'Kontobeskrivning'        , enableColumnActions: false,
      Cell: ({ cell }) => metaLookUpAccountTitle(metadata.BookKeepingAccounts, cell.getValue()),        
    },

    { size: 30, accessorKey: 'Amount',   header: 'Saldo', enableColumnActions: false },        
    ]
  );

  const initialState = { density: 'compact', columnVisibility: { } };      

  if (!loadingmeta) {
    return (
      <ContentWrapper>
        <Sida>
          <MaterialReactTable columns={columns} data={data.Saldo} state={initialState}
              muiTablePaginationProps={{ showFirstButton: false, showLastButton: false, rowsPerPageOptions: [25, 50, 75, 100] ,labelRowsPerPage:"Rader"}}
              renderTopToolbarCustomActions={() => (<span>Saldo</span>)}                      
              muiTablePaperProps={{elevation: 0, sx: {borderRadius: '0',border: '0px dashed #e0e0e0'} }} 
          />                                             
        </Sida>
      </ContentWrapper>        
    );
  } else {
      return (<Loading />);        
  }    
}

export default BookkeepingSaldo;