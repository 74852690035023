import { Container, Form, Alert} from 'react-bootstrap';
import {Sida,Rubrik,ContentWrapper,Button} from "../ContentStyles";

import useBackend, {CallAPI, SpinnerX} from '../../services/useBackend';

import React, { useState, useEffect } from 'react';

const Contact = () => {

    const [resultText, setResultText] = useState("N/A");
    const [resultVariant, setResultVariant] = useState("notset");
    const [resultVisible, setResultVisible] = useState(false);
    const [spinnerHidden, setSpinnerHidden] = useState(true);

    const [size, setSize] = useState({
        x: window.innerWidth,
        y: (window.innerHeight-400)
      });
    
      const updateSize = () => {
        setSize({
          x: window.innerWidth,
          y: (window.innerHeight-400)
        });
      }
      useEffect(() => (window.onresize = updateSize), []);  
      useEffect(() => (window.onscroll = updateSize), []);       

    async function handleSubmit(e) {
        e.preventDefault();

        setSpinnerHidden(false);
        setResultVisible(false);

        const formData = new FormData(e.target),
        formDataObj = Object.fromEntries(formData.entries())

        var PostData = {SystemID:"C", FormData : formDataObj};

        let {resultVariant, resultVisible, resultText, jsondata} = await CallAPI('/backend/forms/contact','POST',JSON.stringify(PostData));

        if (jsondata.errorsException===false) {
            setResultVariant(resultVariant);      
            setResultText(resultText);        
            setSpinnerHidden(true);
            setResultVisible(true);
        } else {
            setResultVariant("warning");
            setResultText(resultText);
            setSpinnerHidden(true);
            setResultVisible(true);
        }  
    }

    return (
        <ContentWrapper>
            <Container>
            <Sida style={{minHeight:size.y}}>
                <Rubrik>Kontakt</Rubrik>
                <Form onSubmit={handleSubmit}>

                    <Form.Group className="mb-3" >
                            <Form.Control type="text" name ="Name" placeholder="Namn"  />
                    </Form.Group>
                    
                    <Form.Group className="mb-3" >
                            <Form.Control type="email" name="Email" placeholder="Email" />
                    </Form.Group>

                    <Form.Group className="mb-3" >
                            <Form.Control type="text" name="Subject" placeholder="Ämne" />
                    </Form.Group>

                    <Form.Group className="mb-3" >
                            <Form.Control as="textarea" rows="3" name="Text"  placeholder='Fråga'/>
                    </Form.Group>

                    <Form.Group className="mb-3"  hidden={spinnerHidden}>
                        <SpinnerX  />
                    </Form.Group>                                      
                        
                    <Form.Group className="mb-3"  hidden={!resultVisible}>
                        <Alert show={resultVisible} variant={resultVariant}  className="FormSpace">{resultText}</Alert> 
                    </Form.Group>        

                    <Form.Group className="mb-3">
                        <Button className="QmeButton" variant="primary" type="submit" disabled={resultVariant=="success"? true: false }>Skicka</Button>
                    </Form.Group>   

                </Form> 
            </Sida>
            </Container>
        </ContentWrapper>
    );
};

export default Contact;