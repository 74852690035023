import Container from 'react-bootstrap/Container';
import { Alert, Button, Card, Table, Modal, Accordion, Form } from 'react-bootstrap';
import {Sida,Rubrik,ContentWrapper,Stycke,StyckeCompact} from "../ContentStyles";
import useBackend, {CallAPI, Loading, SpinnerX, Check, CallAPIGet} from '../../services/useBackend';
import React, { useState ,useEffect, useReducer, fetchData,useMemo} from 'react';
import {useParams, useNavigate, useSearchParams} from 'react-router-dom';
import {MaterialReactTable} from 'material-react-table';
import {DateTimeToLocal, MultiselectGetOptions, MultiselectGetDefaultValues, escapedNewLineToLineBreakTag, ConcatLocalDateAndTime2ISO, SplitoutLocalDateFromISO, SplitoutLocalTimeFromISO, getOptions, metaLookUpTitle, metaLookUpAccountTitle} from '../../services/misc';
import { FaEdit, FaEraser, FaSortDown, FaSortUp, FaDownload, FaCheck } from 'react-icons/fa';

export default function Test(props) {
    const [saving,        setSaving]        = useState(false);
    const [resultVariant, setResultVariant] = useState('warning');
    const [resultText,    setResultText]    = useState('N/A');
    const [resultVisible, setResultVisible] = useState(false);  

    const [selectedWFData, setSelectedWFData] = useState({});

    var typingTimeout = null;

	function handleChange(event){
		if (props.VerificationData.Verification.Guid!="00000000-0000-0000-0000-000000000000") {
			clearTimeout(typingTimeout);
			typingTimeout = setTimeout(handleSave, 475);
		}
	}

	async function handleSave(){
		setSaving(true);
		setResultText("");
		setResultVisible(false);
		const VerificationForm = document.getElementById('VerificationForm');
		const formData = new FormData(VerificationForm);
		const formDataObj = Object.fromEntries(formData.entries());

		//formDataObj.expected_delivery = ConcatLocalDateAndTime2ISO(document.getElementById('expected_delivery').value);

        let {resultVariant, resultVisible, resultText, errorsException, jsondata} = await CallAPI('/api/v1/bookkeeping/verifications/'+props.VerificationData.Verification.Guid,"PUT",JSON.stringify(formDataObj));
        if (resultVariant!="success") {
            setResultVariant(resultVariant);   
            setResultText(resultText);
            setResultVisible(resultVisible);
        } else {

        }
		setSaving(false);
	}    

    const handleUpload = async (e) => {
		e.preventDefault();
		const url = '/api/Files/UploadFiles/'+props.RequestData.Guid;
		const FrmUpload = document.getElementById('FrmUpload');
		const formData = new FormData(FrmUpload);
	  
		const fetchOptions = {
		  method: 'post',
		  body: formData
		};
	  
		const response = await fetch(url, fetchOptions);

		if (response.ok) {
			const jsondata = await response.json();
			props.setSelectedRequestData(jsondata.Request);

			FrmUpload.reset();
		} else {
			throw response;
		}
	};

	const RemoveFile = async (event) => {
		event.preventDefault();
		const fileGuid = event.currentTarget.getAttribute("fileGuid");
		const url = '/api/Files/'+fileGuid;

		let {resultVariant, resultVisible, resultText, errorsException, jsondata} = await CallAPI(url,'DELETE');
		props.setSelectedRequestData(jsondata.Verification);
	};

    const Prev = async (event) => {
		event.preventDefault();
        let {resultVariant, resultVisible, resultText, errorsException, jsondata} = await CallAPIGet('/api/v1/bookkeeping/verifications/' + props.VerificationData.PrevGuid);
        props.setSelectedVerificationData(jsondata);
        const VerificationForm = document.getElementById('VerificationForm');
        VerificationForm.reset();
	};

    const Next = async (event) => {
		event.preventDefault();
        let {resultVariant, resultVisible, resultText, errorsException, jsondata} = await CallAPIGet('/api/v1/bookkeeping/verifications/' + props.VerificationData.NextGuid);
        props.setSelectedVerificationData(jsondata)
        const VerificationForm = document.getElementById('VerificationForm');
        VerificationForm.reset();
	};    

    return (
        <>			  
                <Modal show={props.show} onHide={props.fn} size='xl' >
                    <Modal.Header closeButton>
                        <Modal.Title>{props.VerificationData.Verification.Identity}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <Form id='VerificationForm' name='VerificationForm'>
    
                    <Form.Group className="mb-3" >
                        <Form.Label>Beskrivning</Form.Label>								    
                        <Form.Control as="textarea" rows="3" type="text" name="Description" id="Description" placeholder="Produktbeskrivning" defaultValue={props.VerificationData.Verification.Description} onChange={handleChange} />
                    </Form.Group>

                    <Form.Group className="mb-3" >
                        <Form.Label>Date:</Form.Label>								
                        <Form.Control type="date" name="Date" id="Date" placeholder="Date" value={SplitoutLocalDateFromISO(props.VerificationData.Verification.Date)} onChange={handleChange} />
                    </Form.Group>  

                    <Form.Group className="mb-3" >
                        <Form.Label>Document</Form.Label>								                                
                        <Form.Control type="text" name="Document" id="Document" placeholder="Document" defaulValue="file://192.168.33.211/data_backup/Economy/Documents/" onChange={handleChange}/>
                        <Form.Control type="text" name="Document" id="Document" placeholder="Document" defaultValue={props.VerificationData.Verification.Document} onChange={handleChange} />
                    </Form.Group>      

                    <Form.Group className="mb-3" >
                    <Accordion>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Ve ({props.VerificationData.Verification.BookKeepingTransactions.length})</Accordion.Header>
                            <Accordion.Body>
                                <Table responsive="sm">
                                    <thead>  
                                        <tr><th>Konto</th><th>Dim</th><th>Debet</th><th>Kredit</th><th></th></tr>
                                        {props.VerificationData.Verification.BookKeepingTransactions.map((row) => { 
                                        return <tr key={row.Guid}>
                                        <td>{row.BookKeepingAccountIdentifier} {metaLookUpAccountTitle(props.MetaData, row.BookKeepingAccountIdentifier)}</td>

                                        <td>{row.BookKeepingAccountIdentifier}</td>
                                        <td>{row.Amount>=0 && row.Amount}</td>
                                        <td>{row.Amount<=0 && Math.abs(row.Amount)}</td>
                            
                                        </tr> })}
                                    </thead>
                                </Table>

                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>  
                    </Form.Group>             
    
                    {/* <Form.Group className="mb-3" >
                        <Form.Label>Priority</Form.Label>
                        <Form.Select name="RequestPriorityItemId" id="RequestPriorityItemId" defaultValue={props.RequestData.Guid=="00000000-0000-0000-0000-000000000000" ? (props.MetaData.RequestPriorityItems.find((element) => element.Default == true).Id):(props.RequestData.RequestPriorityItemID)} onChange={handleChange}>
                            {getOptions(props.MetaData.RequestPriorityItems)}
                        </Form.Select>
                    </Form.Group> */}                    

                    {/* <Form.Group className="mb-3" >
                            <Form.Label>Contact</Form.Label>
                            <Form.Select name="ContactID" id="ContactID" defaultValue={props.RequestData.ContactID} onChange={handleChangeContact} on>
                                <option value={0}>----------</option>
                                {getOptions(props.MetaData.Contacts)}
                            </Form.Select>
                            <Form.Label style={{fontSize: '10px'}}><div style={{margin: "0 10px"}}>{props.contactData.orgnr} {props.contactData.eMail} {props.contactData.phonenumber}<br />{props.contactData.street01} {props.contactData.street02} {props.contactData.zip} {props.contactData.city}</div></Form.Label>
                    </Form.Group>	                         */}
	
                    {/* <Form.Group className="mb-3" >
                    <Accordion>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Files ({props.VerificationData.VerificationFiles.length})</Accordion.Header>
                            <Accordion.Body>
                                <Table responsive="sm">
                                    <thead> 
                                        <tr><th>Filename</th><th>Filetype</th><th>User</th><th></th></tr>
                                        {props.RequestData.RequestFiles.map((state) => { 
                                        return <tr>
                                        <td>{state.FileName}</td>
                                        <td>{state.filetype} ({state.fileext})</td>
                                        <td>{metaLookUpTitle(props.MetaData.Users,state.UserID)}</td>
                                        <td><a href={"/files/"+state.Guid+""+state.fileext}><FaDownload title="Download" /></a>&nbsp;<span onClick={RemoveFile} fileGuid={state.Guid}><FaEraser title="Delete" /></span></td>										
                                        </tr> })}
                                    </thead>
                                </Table>

                                <>
                                    <div className="input-group">
                                        <form id="FrmUpload"  method="post" enctype="multipart/form-data">
                                            <input name="file" id="file" type="file" />
                                            <button onClick={handleUpload} type="button">Ladda upp</button>
                                        </form>
                                    </div>
                                </>

                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>  
                    </Form.Group> */}

                    <Form.Group className="mb-3"  hidden={!saving}>
                        <SpinnerX  />
                    </Form.Group>                                      
                    
                    <Form.Group className="mb-3"  hidden={!resultVisible}>
                        <Alert show={resultVisible} variant={resultVariant}  className="FormSpace">{resultText}</Alert> 
                    </Form.Group> 												 													
                  </Form>  
                                      
                  </Modal.Body>
                  <Modal.Footer>

                  <Button variant="danger" onClick={Prev}>Föregående</Button>
                  <Button variant="danger" onClick={Next}>Nästa</Button>
                  <Button variant="danger" onClick={props.fn}>Close</Button>
                  </Modal.Footer>
              </Modal>  
        </>
      );      
};