import React, {useState} from "react";
import {Dropdown, Alert, Button} from 'react-bootstrap';
import {Box, Container, Row, Column, FooterLink, Heading, Address, SocialMediaBlock} from "./FooterStyles";
import useBackend, {XLogOff, IsLoggedIn} from '../services/useBackend';
import { FaSms, FaSignOutAlt, FaKey, FaUser, FaCog } from 'react-icons/fa';
import './Menu.css';

const Top = () => {

  var {loggedIn, isAdmin, isSuperUser, userName } = IsLoggedIn();

  const [isNavCollapsed, setIsNavCollapsed] = useState(true);

  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);

	return (
		<Box>
		<Container>

    <nav className="navbar navbar-expand-lg navbar-dark ">
      <a className="navbar-brand" href="/">Redovisa.nu</a>
      <button className="custom-toggler navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExample09" aria-controls="navbarsExample09" aria-expanded={!isNavCollapsed ? true : false} aria-label="Toggle navigation" onClick={handleNavCollapse}>
        <span className="navbar-toggler-icon"></span>
      </button>
                
  <div className={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse`} id="navbarsExample09">
    <ul className="navbar-nav">

        {loggedIn ? ( 
          <>
            <a style={{color:"white"}} className="nav-item nav-link active" href={process.env.PUBLIC_URL+"/"}>Hem</a>          
            <a style={{color:"white"}} className="nav-item nav-link active" href={process.env.PUBLIC_URL+"/verifications"}>Bokföring</a>     
            <a style={{color:"white"}} className="nav-item nav-link active" href={process.env.PUBLIC_URL+"/accounts"}>Kontoplan</a>     
            <a style={{color:"white"}} className="nav-item nav-link active" href={process.env.PUBLIC_URL+"/saldo"}>Saldo</a>     
            <a style={{color:"white"}} className="nav-item nav-link active" href={process.env.PUBLIC_URL+"/Uploadx"}>Ladda upp SIE-fil</a>                   
          </>
          ) :(
          <>
            <a style={{color:"white"}} className="nav-item nav-link active" href={process.env.PUBLIC_URL+"/"}>Hem</a>
            <a style={{color:"white"}} className="nav-item nav-link active" href={process.env.PUBLIC_URL+"/contact"}>Kontakt</a>       
          </>                  
        )}

        {/* <a style={{color:"white"}} className="nav-item nav-link active" href={process.env.PUBLIC_URL+"/Project"}>Project</a>                
        <a style={{color:"white"}} className="nav-item nav-link active" href={process.env.PUBLIC_URL+"/Example"}>Example</a>                 */}

    </ul>
    <ul className="navbar-nav ms-auto">
      {loggedIn && isNavCollapsed && (
        <>
          <div className="dropdown nav-link">
              <a style={{color:"white"}} href="#" className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">{userName}</a>
              <div className="dropdown-menu dropdown">                            
                  <a href={process.env.PUBLIC_URL+"/mypage"} className="dropdown-item"><FaCog/>&nbsp;Min Profil</a>
                  <a href={process.env.PUBLIC_URL+"/password/change"} className="dropdown-item"><FaKey/>&nbsp;Byt lösenord</a>
                  <a className="dropdown-item" onClick={XLogOff}><FaSignOutAlt/>&nbsp;Logga ut</a>
              </div>
          </div>                    
        </>)
      }

      {loggedIn && !isNavCollapsed && (
        <>      
          <hr style={{color:"white", padding:"2px"}}></hr>
          <div style={{color:"white", padding:"2px"}}>{userName}</div>
          <div>
            <a style={{color:"white", marginRight:"12px"}} className="nav-item nav-link_ active"  onClick={XLogOff}><FaSignOutAlt/>&nbsp;Logga ut</a>
            
            <a style={{color:"white", marginRight:"12px"}} className="nav-item nav-link_ active" href={process.env.PUBLIC_URL+"/password/change"} ><FaKey/>&nbsp;Byt lösenord</a>
            
            <a style={{color:"white", marginRight:"12px"}} className="nav-item nav-link_ active" href={process.env.PUBLIC_URL+"/mypage"} ><FaCog/>&nbsp;Min Profil</a>
          </div>
        </>) 
      }                    

      {!loggedIn && (<><Button className="CloudLoginButton" variant="primary" href="/login">Logga in</Button></>)}  

      
      
    </ul>      
    
  </div>
</nav>    
{isNavCollapsed && (<><div className="Top"><p className="TopLine" id="logo">Ett nytt sätt att se på saken :)</p></div></>)}


		</Container>
		</Box>
	);
};

export default Top;