import Container from 'react-bootstrap/Container';
import { Alert, Button, Card, Table, Modal, Accordion, Form } from 'react-bootstrap';
import {Sida,Rubrik,ContentWrapper,Stycke,StyckeCompact} from "../ContentStyles";
import useBackend, {XLogOff, IsLoggedIn,Loading, Check, CallAPI, CallAPIGet, SpinnerX} from '../../services/useBackend';
import {MaterialReactTable} from 'material-react-table';
import React, { useState ,useEffect, useReducer, fetchData,useMemo} from 'react';
import {DateTimeToLocal, MultiselectGetOptions, MultiselectGetDefaultValues, escapedNewLineToLineBreakTag, ConcatLocalDateAndTime2ISO, SplitoutLocalDateFromISO, SplitoutLocalTimeFromISO, getOptions, metaLookUpTitle, metaLookUpAccountTitle} from '../../services/misc';
import axios from 'axios';

import { FileUploader } from "react-drag-drop-files";

function Tools() {  
    const [loading,       setLoadning]      = useState(false);
    const [name, setName] = useState("");
    const [selectedFile, setSelectedFile] = useState(null);
    const [resultVariant, setResultVariant] = useState('warning');
    const [resultText,    setResultText]    = useState('N/A');
    const [resultVisible, setResultVisible] = useState(false);    

  const {loading:loadingmeta, data:metadata} =  useBackend(process.env.PUBLIC_URL+'/api/meta');    


  const fileTypes = ["SI", "SE", "txt", "dat"];

  const submitForm = () => {
      const formData = new FormData();
      formData.append("filetype", "SEB");
      formData.append("name", "file");
      formData.append("file", selectedFile);
      setLoadning(true);

      axios
      //.post("/api/v1/upload", formData, {headers: {'X-API-KEY': "8VPDG6JWV8TT7N5iUQ38rYig6efkqecy"}} )
      .post("/api/v1/upload", formData, {headers: {'accesstoken': localStorage.getItem('access_token')}} )
      .then((res) => {
          console.log(res.data);          
          setResultText("Filen laddades upp");
          setResultVariant("success");
          setResultVisible(true);
      })
      .catch((err) => {setResultText("Något gick fel");setResultVisible(true);});

      setSelectedFile(null);
      setLoadning(false);
  };

  const handleChange = (file) => {
    setSelectedFile(file);
    setResultVisible(false);
    setResultText("");
  };

  if (!loadingmeta) {
    return (
      <ContentWrapper>
        <Sida>

          <Form>
              <Form.Group className="mb-3">
                  <FileUploader submitForm="" handleChange={handleChange} name="file"  label="Välj eller släpp en fil här" uploadedLabel="Fil vald, tryck på ladda upp!" multiple={false} maxSize={5} style={{height:"60px", width:"100%"}} height="60px"/>
                  <Form.Label>Tillåtna filtyper: {fileTypes.map((ft) => {return <span key={ft}>{ft} </span>} )}</Form.Label>
              </Form.Group>  

              <Form.Group className="mb-3">                            
                  <Button disabled={false} className="QmeButton" variant="primary" type="button" onClick={submitForm}>Ladda upp</Button>
              </Form.Group>  

              <Form.Group className="mb-3"  hidden={!loading}>
                  <SpinnerX  />
              </Form.Group>                                      
                  
              <Form.Group className="mb-3"  hidden={!resultVisible}>
                  <Alert show={resultVisible} variant={resultVariant}  className="FormSpace">{resultText}</Alert> 
              </Form.Group>                                
          </Form>          
                                          
        </Sida>
      </ContentWrapper>        
    );
  } else {
      return (<Loading />);        
  }    
}

export default Tools;